import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { UnderConstruction } from '../components/UnderConstruction';

export default function Example() {
  return (
    <Layout>
      <SEO title={null} titleTemplate="" />
      <div className="flex items-center justify-center w-screen h-screen">
        <UnderConstruction />
      </div>
    </Layout>
  );
}
