import React from 'react';
import UnderConstructionImage from '../images/under-construction.svg';
import { SectionContainer } from './SectionContainer';

export const UnderConstruction = ({ className = undefined }) => (
  <SectionContainer className={className}>
    <div className="flex flex-col items-center justify-center -mt-20">
      <UnderConstructionImage className="w-full h-auto max-w-screen-sm m-4" />
      <h1 className="mt-2 text-xl text-center whitespace-pre-wrap sm:text-3xl">{`홈페이지 개편 중 입니다.`}</h1>
    </div>
  </SectionContainer>
);
