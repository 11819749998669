import React from 'react';
import classNames from 'classnames';

export const SectionContainer = ({
  children,
  id = undefined,
  className = '',
  background = undefined,
  maxWidth = 960,
}) => (
  <section id={id} className={classNames('text-gray-600 body-font relative', className)} style={{ background }}>
    <div className="px-2 py-6 mx-auto sm:px-24 sm:py-8" style={{ maxWidth }}>
      {children}
    </div>
  </section>
);
